// Material Design Input

.mat-mdc-form-field {
    width: 100%;

    .mat-mdc-text-field-wrapper {
        &.mdc-text-field--outlined .mat-mdc-form-field-infix {
            display: flex;
        }
    }

    &--small {
        .mat-mdc-text-field-wrapper {
            height: 38px;
            min-height: 38px;
            max-height: 38px;

            &.mdc-text-field--outlined .mat-mdc-form-field-infix {
                height: 38px;
                min-height: 38px;
                max-height: 38px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }

        .material-symbols-outlined {
            font-size: 23px !important;
        }
    }

    &--no-validation {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}
