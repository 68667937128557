@import '../../jplus-design-system-angular/src/themes/colours';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  background-color: $background-colour;
}

.j-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.j-sheet-job {
  .mat-bottom-sheet-container {
    padding: 0;
    min-width: 100vw;
    min-height: calc(100vh - 64px);
    height: calc(100vh - 64px);
    max-width: none;
  }
}

.jplus-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  height: 64px;

  z-index: 2;
}

.jplus-nav {
  position: fixed;
  top: 64px;
  left: 0;
  bottom: 0;

  width: 190px;

  overflow-y: auto;

  &.collapsed {
    width: 60px;
  }
}

.jplus-aside {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 190px;

  overflow-y: auto;

  &.collapsed {
    left: 60px;
  }
}

