.j-alert {
  display: flex;
  gap: 12px;
  width: 100%;
  height: auto;
  margin: 0;
  border: 0;
  border-radius: 4px;
  padding: 12px;

  background-color: $grey-colour--lightest;
  color: $grey-colour--dark;

  box-sizing: border-box;

  &-content {
    &__heading {
      margin: 0;
      margin-bottom: 12px;
      color: inherit;
    }

    &__text {
      margin: 0;
      font-size: 14px;
      color: inherit;
    }
  }

  &--info {
    background-color: $info-colour--light;
    color: $info-colour--dark;
  }

  &--warning {
    background-color: $warning-colour--light;
    color: $warning-colour--dark;
  }

  &--success {
    background-color: $success-colour--light;
    color: $success-colour--dark;
  }

  &--error {
    background-color: $error-colour--light;
    color: $error-colour--dark;
  }
}
