.mat-mdc-standard-chip.mat-mdc-chip-highlighted {
  &.mat-primary,
  &.mat-accent {
    --mdc-chip-label-text-color: #FFFFFF;
  }
}

.mat-mdc-chip.mat-mdc-standard-chip.is-small {
  --mdc-chip-container-height: 24px !important;
  --mdc-chip-label-text-size: 13px !important;
}
