$header-padding: 8px !default;

.header {
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 64px;
  line-height: 32px;

  padding: $header-padding calc($header-padding * 2);
  border-bottom: 1px solid $grey-colour--lighter;
  background-color: $white-colour;

  box-sizing: border-box;

  &--gap {
    gap: 12px;
  }

  &__section {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__logo-image {
    height: 32px;
  }

  &__separator {
    width: 1px;
    height: 32px;
    margin: 0 20px;
    border-left: 1px solid $grey-colour--lighter;
  }

  &__client {
    position: relative;
    left: -12px;
    height: 40px;
    padding: 12px;
    user-select: none;

    &:hover {
      background-color: $black--hover-state;
      cursor: pointer;
    }

    &-name {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }

    &-icon {
      font-size: 18px;
      font-weight: 500;
    }

    &-select {
      &-dropdown {
        height: 0;
        opacity: 0;
        overflow: hidden;

        transition: opacity .2s linear;
        will-change: opacity;

        &--active {
          animation-name: fadeIn;
          animation-duration: .2s;
          animation-timing-function: linear;
          animation-delay: 0s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        &--hide {
          animation-name: fadeOut;
          animation-duration: .1s;
          animation-timing-function: linear;
          animation-delay: 0s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  jplus-client-select {
    position: absolute;
    top: 5px;
    left: -7px;
    z-index: 2;
  }

  &__expander {
    width: 1px;
    height: 32px;
    margin: 0 auto;
  }

  &__user {
    position: relative;

    width: 40px;
    height: 40px;
    line-height: 40px;

    border-radius: 50%;
    background-color: $grey-colour--light;

    text-align: center;

    &-label {
      margin: 0;
      font-weight: 500;
      color: $white-colour;
    }
  }
}

@keyframes fadeIn {
  0% {
    height: auto;
    opacity: 0;
    overflow: initial;
  }
  100% {
    height: auto;
    opacity: 1;
    overflow: initial;
  }
}

@keyframes fadeOut {
  0% {
    height: auto;
    opacity: 1;
    overflow: initial;
  }
  99% {
    height: auto;
    opacity: 0;
    overflow: initial;
  }
  100% {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}
