// Utilities
//
// Breakpoint vars
$xs: 0;
$sm: 576px;
$md: 768px;
$ml: 992px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1600px;

//
// Sets all padding and margin values

$displays:              none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$margin-padding:        0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 100;

// Shadows
//
// Sets all shadows values

$shadow-global:         0px 2px 3px 0px rgba(0, 0, 0, 0.3) !default;
$shadow-card:           0px 0px 2px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.2) !default;

// Layout
//
// Set the width of the gutters.

$desktop-gutter-left:   24px !default;
$desktop-gutter-right:  24px !default;

$desktop-gutter:        24px !default;

$mobile-gutter-left:    16px !default;
$mobile-gutter-right:   16px !default;

$mobile-gutter:         16px !default;


// Borders

$border-radius-global:  12px !default;
$border-radius-small:  6px !default;
