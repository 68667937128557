.navigation {
    position: relative;

    width: 100%;
    height: 100%;

    box-sizing: border-box;

    &--collapsed {
        width: 60px;

        .navigation__list-item p {
            display: none;
        }
    }

    &__list {
        display: flex !important;
        flex-direction: column;
        height: 100%;
        padding: 12px 0 !important;
        box-sizing: border-box;

        &-item {
            display: flex;
            align-items: center;

            height: 48px;
            line-height: 48px;

            cursor: pointer;

            &-image {
                width: 24px;
                height: 24px;
                line-height: 24px;
            }

            &--end {
                margin-bottom: auto !important;
            }

            p {
                margin: 0 12px;
            }
        }

        // Material Design Overrides
        .mdc-list-item {
            border-left: 4px solid transparent;

            &:focus {
                &:before {
                    background-color: transparent;
                    opacity: 0;
                }
            }

            &.mdc-list-item--with-one-line {
                min-height: 48px;
                border-top-right-radius: 28px;
                border-bottom-right-radius: 28px;

                &:not(:last-of-type) {
                    margin-bottom: 8px;
                }

                &.is-active {
                    border-left: 4px solid $navigation-colour;
                    background-color: $navigation-colour--light;

                    &:hover {
                        &:before {
                            background-color: transparent;
                            opacity: 0;
                        }
                    }

                    p, span {
                        color: $navigation-colour;
                    }
                }

                .mdc-list-item__end {
                    display: none;
                }
            }
        }
    }
}
