@import '../../themes/colours';

.table {
  &__column--icon {
    width: 48px;
  }

  &__rows--click {
    table tr:has(> td) {
      &:hover {
        background-color: $black--hover-state;
        cursor: pointer;
      }
    }
  }

  &__row--collapse {
    display: none;
  }

  table {
    width: 100%;
    padding-top: 32px;
    border: 0;
    border-spacing: 0;
    background-color: transparent;

    tr {
      border: 0;
      height: auto;

      &:has(> td) {
        box-shadow: inset 0px 1px 0px $grey-colour--lighter;
        background-color: transparent;
      }
    }

    tr.table__row--expand {
      + tr {
        display: table-row !important;
        width: 100%;
      }
    }

    th {
      position: relative;
      top: -16px;
      height: 14px;
      line-height: 14px;

      padding: 0 16px;
      border: 0;
      border-right: 2px solid $grey-colour--lighter;

      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: $grey-colour--dark;

      &:last-child {
        border-right: 0;
      }
    }

    td {
      height: 48px;
      padding: 8px 0 8px 16px;
      border: 0;

      font-size: 15px;

      &:last-child {
        padding: 8px 16px;
      }

      & > * {
        vertical-align: middle;
      }

      p {
        margin: 4px 0 24px !important;

        &:last-child {
          margin-bottom: 4px !important;
        }
      }

      ul, ol {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      // todo: need to add a specific css class because it breaks other images
      img:not(.j-client-logo__image) {
        width: 24px;
        height: 24px;
        margin-right: 6px;
        padding: 6px;
        border: 1px solid $grey-colour--lighter;
        border-radius: 8px;
      }
    }
  }
}

.button--rotate-180 {
  transform: rotate(180deg);
}
