@import '../../themes/colours';
@import '../../themes/utilities';

.card {
    position: relative;

    width: 100%;
    height: auto;
    border-radius: 6px;

    background-color: $white-colour;
    box-shadow: $shadow-card;
    box-sizing: border-box;
}
