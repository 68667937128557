// Material Design Button

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
    &.mat-mdc-button-base {
        height: 38px;
        padding: 0 16px;
        font-size: 14px;
    }

    .mdc-button__label {
        display: flex;
        align-items: center;
    }
}

.mat-mdc-button--small {
    height: 36px !important;

    .material-symbols-outlined {
        font-size: 18px !important;
    }
}

.mat-mdc-button--large {
    height: 48px !important;
    padding: 0 24px !important;
    font-size: 16px !important;
}

.mat-mdc-outlined-button:not(:disabled) {
    border-color: inherit !important;
}

.mat-mdc-fab {
    .material-symbols-outlined {
        font-size: 32px;
        position: relative;
        top: 1px;
    }
}
